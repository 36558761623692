import { createFeature, createReducer, on } from '@ngrx/store';
import { BlockDefinition } from 'src/app/core/interfaces/block-definition.interface';
import { PatternSummary } from 'src/app/core/interfaces/pattern-summary.interface';
import { PatternDetail } from '../../../core/interfaces/pattern-detail.interface';
import * as patternsApiActions from './actions/patterns-api.action';
import * as patternsActions from './actions/patterns.action';
import * as patternsFiltersActions from './actions/pattern-filters.action';
import { ID } from 'src/app/core/definitions/types';
import { NodeInfo } from '../../linked-trees/interfaces/node-info';
import { Push } from '../../../core/interfaces/push.interface';
import { PushByFramework } from '../../../core/interfaces/pushes-by-framework.interface';
import { PushSummary } from '../../../core/interfaces/push-summary.interface';
import { InlineEditorId } from '../../shared-nodes/interfaces/field-editor-id';
import { HeaderItem } from '../../layout/interfaces/header-item';
import {
    adaptToState,
    PatternValidationState
} from '../../../core/interfaces/general-validation-result.interface';
import { discardUnsavedChangesAndNavigate } from '../../../store/actions/root.actions';
import {
    ClickOnReferenceInfo,
    ClickOnValueInfo
} from '../types/click-on-reference-types';
import { treeHasErrors } from '../../../core/helpers/pattern-utils';
import { ElementDefinitionDetail } from '../../../core/interfaces/element-definition-detail.interface';
import { LinkSummary } from 'src/app/core/interfaces/link-summary';

export interface PatternsState {
    blockDefinitions: BlockDefinition[];
    creationMode: boolean;
    patterns: PatternSummary[];
    draftPatterns: PatternDetail[];
    selectedPattern: PatternDetail | null;
    modified: boolean;
    patternHeader: HeaderItem | null;
    currentlyEditingField: {
        nodeInfo: NodeInfo;
        id: InlineEditorId;
        nodeDisplayName: string;
        initialValue: string | null;
        codeName: string;
    } | null;
    currentClickOnReferenceInfo: ClickOnReferenceInfo | ClickOnValueInfo | null;
    tryingPattern: boolean;
    loadingDetail: boolean;
    loadingPushes: boolean;
    selectedPush: Push | null;
    pushes: PushByFramework[] | null;
    drawingToMostRecentPush: Record<ID, PushSummary>;
    loadingPush: boolean;
    loadingPatternList: boolean;
    patternValidationResult: PatternValidationState | null;
    elementDefinitions: ElementDefinitionDetail[];
    associatedLinks: LinkSummary[];
    loadingAssociatedLinks: boolean;
    archivedPatterns: PatternSummary[];
    loadingArchivedList: boolean;
}

export const initialState: PatternsState = {
    blockDefinitions: [],
    creationMode: false,
    draftPatterns: [],
    patterns: [],
    selectedPattern: null,
    modified: false,
    currentlyEditingField: null,
    currentClickOnReferenceInfo: null,
    patternHeader: null,
    loadingDetail: false,
    loadingPushes: false,
    loadingPush: false,
    loadingPatternList: false,
    selectedPush: null,
    pushes: null,
    drawingToMostRecentPush: {},
    tryingPattern: false,
    patternValidationResult: null,
    elementDefinitions: [],
    associatedLinks: [],
    loadingAssociatedLinks: false,
    archivedPatterns: [],
    loadingArchivedList: false
};

export const patternsFeature = createFeature({
    name: 'patterns',
    reducer: createReducer(
        initialState,
        on(patternsApiActions.getPatternById, (state) => ({
            ...state,
            creationMode: false,
            selectedPattern: null,
            modified: false,
            currentlyEditingField: null,
            loadingDetail: true,
            tryingPattern: false,
            patternHeader: null
        })),
        on(patternsApiActions.getArchivedPatternById, (state) => ({
            ...state,
            creationMode: false,
            selectedPattern: null,
            modified: false,
            currentlyEditingField: null,
            loadingDetail: true,
            tryingPattern: false,
            patternHeader: null
        })),
        on(patternsApiActions.getPatternByIdSuccess, (state, { pattern }) => ({
            ...state,
            selectedPattern: pattern,
            loadingDetail: false
        })),
        on(patternsApiActions.getPatternByIdFail, (state) => ({
            ...state,
            loadingDetail: false
        })),
        on(patternsActions.setPattern, (state, { pattern, isDraft }) => {
            return {
                ...state,
                creationMode: isDraft,
                selectedPattern: pattern,
                currentlyEditingField: null,
                tryingPattern: false
            };
        }),
        on(patternsApiActions.deletePattern, (state) => ({
            ...state,
            loadingDetail: true,
            patternHeader: null
        })),
        on(patternsApiActions.deletePatternSuccess, (state) => ({
            ...state,
            loadingDetail: false
        })),
        on(patternsApiActions.deletePatternFail, (state) => ({
            ...state,
            selectedPattern: null,
            loadingDetail: false
        })),
        on(patternsApiActions.getPatterns, (state) => ({
            ...state,
            patterns: [],
            tryingPattern: false,
            loadingPatternList: true
        })),
        on(patternsApiActions.getPatternsSuccess, (state, { patterns }) => ({
            ...state,
            patterns,
            loadingPatternList: false
        })),
        on(patternsApiActions.getPatternsFail, (state) => ({
            ...state,
            loadingPatternList: false
        })),
        on(patternsApiActions.getArchivedPatterns, (state) => ({
            ...state,
            archivedPatterns: [],
            loadingArchivedList: true
        })),
        on(
            patternsApiActions.getArchivedPatternsSuccess,
            (state, { archivedPatterns }) => ({
                ...state,
                archivedPatterns,
                loadingArchivedList: false
            })
        ),
        on(patternsApiActions.getArchivedPatternsFail, (state) => ({
            ...state,
            loadingArchivedList: false
        })),
        on(patternsApiActions.createPattern, (state) => ({
            ...state,
            loadingDetail: true
        })),
        on(patternsApiActions.createPatternSuccess, (state, { draftId, pattern }) => ({
            ...state,
            creationMode: false,
            draftPatterns: state.draftPatterns.filter((p) => p.id !== draftId),
            patterns: [pattern, ...state.patterns],
            selectedPattern: pattern,
            modified: false,
            loadingDetail: false
        })),
        on(patternsApiActions.createPatternFail, (state) => ({
            ...state,
            loadingDetail: false
        })),
        on(patternsActions.createPatternDraft, (state, { draft }) => ({
            ...state,
            selectedPush: null,
            selectedPattern: null,
            draftPatterns: [...state.draftPatterns, draft]
        })),
        on(patternsActions.deletePatternDraft, (state, { id }) => ({
            ...state,
            draftPatterns: state.draftPatterns.filter((p) => p.id !== id)
        })),
        on(patternsApiActions.deletePatternSuccess, (state, { id }) => ({
            ...state,
            patterns: state.patterns.filter((p) => p.id !== id),
            selectedPattern: null
        })),
        on(patternsApiActions.updatePattern, (state) => ({
            ...state,
            loadingDetail: true,
            selectedPattern: null
        })),
        on(patternsApiActions.updatePatternSuccess, (state, { pattern }) => ({
            ...state,
            patterns: state.patterns.map((p) => (p.id === pattern.id ? pattern : p)),
            selectedPattern: pattern,
            modified: false,
            loadingDetail: false,
            currentlyEditingField: null
        })),
        on(patternsApiActions.updatePatternFail, (state) => ({
            ...state,
            loadingDetail: false
        })),
        on(patternsActions.changeDetailHeader, (state, { patternChanged }) => {
            if (
                state.selectedPattern?.title === patternChanged.title &&
                state.selectedPattern.description === patternChanged.description
            ) {
                return state;
            }

            return {
                ...state,
                patternHeader: {
                    name: patternChanged.title,
                    description: patternChanged.description
                },
                modified: true
            };
        }),
        on(patternsActions.patternDetailDiscard, (state, { discard }) => {
            const drafts =
                discard && state.creationMode
                    ? state.draftPatterns.filter(
                          (item) => item.id !== state.selectedPattern?.id
                      )
                    : state.draftPatterns;

            return {
                ...state,
                modified: false,
                patternHeader: discard ? null : state.patternHeader,
                selectedPattern: discard ? null : state.selectedPattern,
                loadingDetail: discard ? true : state.loadingDetail,
                draftPatterns: drafts,
                currentlyEditingField: discard ? null : state.currentlyEditingField,
                selectedPush: discard ? null : state.selectedPush
            };
        }),
        on(patternsActions.setPatternModified, (state, { modified }) => {
            return {
                ...state,
                modified
            };
        }),
        on(patternsFiltersActions.editFilter, (state) => {
            return {
                ...state,
                modified: true
            };
        }),
        on(patternsFiltersActions.deleteFilter, (state) => {
            return {
                ...state,
                modified: true
            };
        }),
        on(
            patternsActions.setEditingField,
            (state, { nodeInfo, id, nodeDisplayName, initialValue, codeName }) => {
                return {
                    ...state,
                    currentlyEditingField: {
                        nodeInfo,
                        id: id,
                        nodeDisplayName,
                        initialValue,
                        codeName
                    }
                };
            }
        ),
        on(
            patternsActions.disableEditingField,
            patternsActions.disableEditingFieldInline,
            (state) => {
                return {
                    ...state,
                    currentlyEditingField: null
                };
            }
        ),
        on(
            patternsActions.clickOnReference,
            (state, { propertyName, groupName, nodeInfo }) => {
                return {
                    ...state,
                    currentClickOnReferenceInfo: {
                        destination: nodeInfo,
                        origin: state.currentlyEditingField?.nodeInfo,
                        propertyName,
                        groupName
                    }
                };
            }
        ),
        on(
            patternsActions.clickOnReferenceField,
            (state, { fieldDefinitionName, fieldDefinitionCodeName, nodeInfo }) => {
                return {
                    ...state,
                    currentClickOnReferenceInfo: {
                        destination: nodeInfo,
                        origin: state.currentlyEditingField?.nodeInfo,
                        fieldDefinitionName,
                        fieldDefinitionCodeName
                    }
                };
            }
        ),
        on(patternsActions.clickOnValue, (state, { value }) => {
            return {
                ...state,
                currentClickOnReferenceInfo: {
                    value
                }
            };
        }),
        on(patternsActions.clickOnReferenceConsumed, (state) => {
            return {
                ...state,
                currentClickOnReferenceInfo: null
            };
        }),
        on(
            patternsActions.pushSelectionChange,
            (state, { id, autoVersion, targetFramework }) => {
                if (!state.selectedPattern || !id) return state;
                const newSelectedPattern = {
                    ...state.selectedPattern,
                    autoVersion,
                    selectedDrawing: autoVersion ? id : '',
                    selectedPush: autoVersion ? '' : id,
                    targetFramework: targetFramework
                };
                return {
                    ...state,
                    selectedPattern: newSelectedPattern,
                    modified: true
                };
            }
        ),
        on(patternsActions.setTryingPattern, (state, { tryingPattern }) => ({
            ...state,
            tryingPattern
        })),
        on(patternsActions.patternTreeChange, (state, { autocadTree, workRoadsTree }) => {
            const hasErrors = treeHasErrors(workRoadsTree);
            return {
                ...state,
                selectedPattern: {
                    ...state.selectedPattern,
                    workRoadsTree,
                    autocadTree,
                    hasErrors
                } as any
            };
        }),
        on(patternsActions.getPushes, (state) => ({
            ...state,
            pushes: null,
            loadingPushes: true
        })),
        on(patternsActions.getPushesSuccess, (state, { linkGroup }) => ({
            ...state,
            pushes: linkGroup.pushes,
            loadingPushes: false,
            drawingToMostRecentPush: linkGroup.drawingToMostRecentPush
        })),
        on(patternsActions.getPushesFail, (state) => ({
            ...state,
            pushes: null,
            loadingPushes: false
        })),
        on(patternsActions.getPush, (state) => ({
            ...state,
            selectedPush: null,
            loadingPush: true
        })),
        on(patternsActions.getPushSuccess, (state, { push }) => ({
            ...state,
            selectedPush: push,
            loadingPush: false
        })),
        on(patternsActions.navigateToPattern, (state) => {
            return {
                ...state,
                selectedPush: null,
                loadingPush: false
            };
        }),
        on(patternsActions.navigateToArchivedPattern, (state) => {
            return {
                ...state,
                selectedPush: null,
                loadingPush: false
            };
        }),
        on(patternsApiActions.validatePatternSuccess, (state, { result }) => ({
            ...state,
            patternValidationResult: adaptToState(result)
        })),
        on(patternsApiActions.fieldChangeResetGeneralErrors, (state) => ({
            ...state,
            patternValidationResult: null
        })),
        on(discardUnsavedChangesAndNavigate, (state) => ({
            ...state,
            draftPatterns: initialState.draftPatterns,
            modified: false
        })),
        on(patternsApiActions.getElementDefinitionForPatterns, (state) => ({
            ...state,
            elementDefinitions: []
        })),
        on(
            patternsApiActions.getElementDefinitionForPatternsSuccess,
            (state, { elementDefinitions }) => ({
                ...state,
                elementDefinitions
            })
        ),
        on(patternsActions.getAssociatedLinks, (state) => {
            return {
                ...state,
                loadingAssociatedLinks: true
            };
        }),
        on(patternsApiActions.getAssociatedLinksSuccess, (state, { links }) => ({
            ...state,
            loadingAssociatedLinks: false,
            associatedLinks: links
        })),
        on(patternsActions.cleanLinkAssociations, (state) => ({
            ...state,
            loadingAssociatedLinks: false,
            associatedLinks: []
        }))
    )
});
