import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeColor } from '../custom-button/custom-button.component';

@Component({
    selector: 'app-custom-expansion-panel',
    templateUrl: './custom-expansion-panel.component.html',
    styleUrls: ['./custom-expansion-panel.component.scss']
})
export class CustomExpansionPanelComponent {
    @Input() set expanded(value: boolean) {
        if (this._expanded !== value) {
            this._expanded = value;
        }
    }
    _expanded = false;
    @Input() disableExpansion = false;
    @Input() hideButtonExpansion = false;
    @Input() buttonExpansionPosition: 'left' | 'right' = 'right';
    @Input() cardStyle: 'regular' | 'solid' | 'light' = 'regular';
    @Input() cardColor?: string;
    @Input() toggleThemeColor?: ThemeColor;
    @Input() toggleStyleColor?: string;
    @Input() clickPropagationMode = {
        contentShouldPropagate: true,
        titleShouldPropagate: false
    };

    @Output() expandedChange = new EventEmitter<boolean>();

    onButtonClick() {
        this.changeExpand(!this._expanded);
    }

    onExpandedChange(value: boolean) {
        this.changeExpand(value);
    }

    changeExpand(value: boolean) {
        if (value !== this._expanded) {
            this._expanded = value;
            this.expandedChange.emit(value);
        }
    }
}
