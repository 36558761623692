import { Node } from '../../modules/linked-trees/interfaces/node';
import { AutocadTreeNode } from '../interfaces/autocad-tree-node.interface';
import { WorkroadsTreeNode } from '../interfaces/workroads-tree-node.interface';
import { FieldExpressionDefinition } from '../interfaces/field-expression-definition.interface';
import { ID } from '../definitions/types';
import { WorkroadsNodeContent } from '../interfaces/workroads-node-content.interface';
import { WorkroadsTree } from '../interfaces/workroads-tree.interface';

export function* flattenTree(nodes: Node[]): Iterable<Node> {
    for (const node of nodes) {
        yield node;
        if (node.children) yield* flattenTree(node.children);
    }
}

export function* flattenAutocadTree(nodes: AutocadTreeNode[]): Iterable<AutocadTreeNode> {
    for (const node of nodes) {
        yield node;
        if (node.childrenDefinitions) yield* flattenAutocadTree(node.childrenDefinitions);
    }
}

export function* flattenWorkRoadsTree(
    nodes: WorkroadsTreeNode[]
): Iterable<WorkroadsTreeNode> {
    for (const node of nodes) {
        yield node;
        if (node.childrenDefinitions)
            yield* flattenWorkRoadsTree(node.childrenDefinitions);
    }
}

export function nodeHasErrors(node: WorkroadsNodeContent): boolean {
    const nodeHasQuantityErrors = !!node.quantityErrors?.length;
    const itemHasErrors = node.fieldExpressions.some((fieldExpression) => {
        return !!fieldExpression.errors?.length;
    });
    return nodeHasQuantityErrors || itemHasErrors;
}

export function treeHasErrors(tree: WorkroadsTree): boolean {
    const nodes = [...flattenWorkRoadsTree(tree.childrenDefinitions)];
    return nodes.some((node) => nodeHasErrors(node));
}

export function findFieldInPattern(
    id: { nodeId: ID; fieldDefinitionId: ID },
    nodes: WorkroadsTreeNode[]
): FieldExpressionDefinition | null {
    for (const node of nodes) {
        if (node.id === id.nodeId) {
            const field = node.fieldExpressions.find(
                (item) => item.fieldDefinitionId === id.fieldDefinitionId
            );
            if (field) {
                return field;
            }
        }
        if (node.childrenDefinitions) {
            const field = findFieldInPattern(id, node.childrenDefinitions);
            if (field) {
                return field;
            }
        }
    }
    return null;
}
