import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { fromRoot } from 'src/app/store';
import { Route } from 'src/app/core/constants/feature';
import { ToolbarHostDirective } from '../../directives/toolbar-host.directive';
import { ToolbarElementsComponent } from '../../../elements/components/toolbar-elements/toolbar-elements.component';
import { ToolbarPatternsComponent } from '../../../patterns/components/toolbar-patterns/toolbar-patterns.component';
import { ToolbarViewsComponent } from 'src/app/modules/views/components/toolbar-views/toolbar-views.component';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    animations: [
        trigger('toolBar', [
            state('show', style({ top: '0px' })),
            state('hide', style({ top: '-70px' })),
            transition('show => hide', [animate('0.3s')]),
            transition('hide => show', [animate('0.3s')])
        ])
    ]
})
export class ToolbarComponent implements OnDestroy {
    @Input() hideToolbar!: boolean;
    @Input() opened!: boolean;

    @Output() toggleOpened = new EventEmitter<boolean>();
    @ViewChild(ToolbarHostDirective, { static: true }) toolbarHost!: ToolbarHostDirective;

    // Store the toolbar here if whe need it to interact with click outside
    toolbarComponent?: ComponentRef<
        ToolbarElementsComponent | ToolbarPatternsComponent | ToolbarViewsComponent
    >;

    subscriptions: Subscription[] = [];
    currentRoute: Route = Route.other;

    constructor(private store: Store) {}

    get isPatterns() {
        return this.currentRoute === Route.patterns;
    }

    get isElements() {
        return this.currentRoute === Route.elements;
    }

    get isViews() {
        return this.currentRoute === Route.views;
    }

    ngOnInit() {
        this.subscriptions.push(
            this.store.select(fromRoot.selectCurrentFeature).subscribe((currentRoute) => {
                this.currentRoute = currentRoute;
                this.loadFeatureToolbar(currentRoute);
            })
        );
    }

    loadFeatureToolbar(currentRoute: Route): void {
        const viewContainerRef = this.toolbarHost.viewContainerRef;
        viewContainerRef.clear();
        if (currentRoute === Route.elements) {
            this.toolbarComponent =
                viewContainerRef.createComponent<ToolbarElementsComponent>(
                    ToolbarElementsComponent
                );
        } else if (currentRoute === Route.patterns) {
            this.toolbarComponent = viewContainerRef.createComponent(
                ToolbarPatternsComponent
            );
        } else if (currentRoute === Route.views) {
            this.toolbarComponent =
                viewContainerRef.createComponent<ToolbarViewsComponent>(
                    ToolbarViewsComponent
                );
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }

    onMenuButtonClick(value: boolean) {
        this.toggleOpened.emit(value);
    }

    onClick(inside: boolean) {
        if (!inside && this.toolbarComponent) {
            this.toolbarComponent.instance.clickOutside();
        }
    }
}
