import { createAction, props } from '@ngrx/store';
import { ID } from '../../../../core/definitions/types';
import { PatternFilter } from '../../../../core/interfaces/autocad-node-content.interface';
import {
    ExternalObjectContent,
    NodeFilterResult
} from '../../../../core/interfaces/node-filter.result';
import { BatchFilterPushesBody, FilterPushesBody } from '../pattern-filters.selectors';
import { ElementType } from '../../enums/elementType';

export const editFilter = createAction(
    '[Patterns Filters] Edit Filter',
    props<{ filterId: ID; nodeId: ID; filter: PatternFilter<any>; query: string }>()
);

export const deleteFilter = createAction(
    '[Patterns Filters] Delete Filter',
    props<{ filterId: ID; nodeId: ID }>()
);

export const addEmptyFilter = createAction(
    '[Patterns Filters] Add Empty Filter',
    props<{ nodeId: ID }>()
);

export const getExternalObjectContent = createAction(
    '[Patterns Filters] Get External Object Content',
    props<{ externalObjectTypes: ElementType[] }>()
);

export const getExternalObjectContentSuccess = createAction(
    '[Patterns Filters] GET External Object Content Success',
    props<{ content: { [type: number]: ExternalObjectContent } }>()
);

export const getExternalObjectContentFailure = createAction(
    '[Patterns Filters] GET External Object Content Failure',
    props<{ error: string | null }>()
);

export const toggleExpanded = createAction(
    '[Patterns Filters] Toggle expanded filter',
    props<{ filterId: ID; value: boolean }>()
);

export const filterPushes = createAction(
    '[Patterns Filters] POST Filter Pushes',
    props<{ nodeId: ID; body: FilterPushesBody }>()
);

export const filterEmptyPush = createAction(
    '[Patterns Filters] POST Filter Empty Push',
    props<{ nodeId: ID }>()
);

export const batchFilterPushes = createAction(
    '[Patterns Filters] POST Batch Filter Pushes',
    props<{ body: BatchFilterPushesBody }>()
);
export const batchFilterPushesSuccess = createAction(
    '[Patterns Filters] POST Batch Filter Pushes Success',
    props<{
        body: BatchFilterPushesBody;
        result: NodeFilterResult[];
    }>()
);
export const batchFilterPushesFail = createAction(
    '[Patterns Filters] POST Batch Filter Fail',
    props<{ error: string }>()
);

export const filterPushesSuccess = createAction(
    '[Patterns Filters] POST Filter Pushes Success',
    props<{
        nodeId: ID;
        body: FilterPushesBody;
        result: NodeFilterResult | null;
    }>()
);
export const filterPushesFail = createAction(
    '[Patterns Filters] POST Filter Pushes Fail',
    props<{ nodeId: ID; error: string | undefined }>()
);
